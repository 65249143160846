import { Container, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";

import { useSelectedView } from "../../services/hooks/localStorageHooks";
import { useRepositoryData } from "../../services/hooks/repositoryData";
import { RepositorySelector } from "../RepositorySelector";
import { BranchAutocomplete } from "./BranchAutocomplete";
import { ExportDropdown } from "./filters/ExportDropdown";
import { LanguageCodeSelector } from "./filters/LanguageCodeSelector";
import { Searchbar } from "./filters/Searchbar";

export const ViewType = {
    localizations: {
        editable: true
    },
    export: {
        editable: false
    }
};

export type ViewTypeKeys = keyof typeof ViewType;

interface ViewPickerProps
{
    searchChanged: (search: string) => void;
}

export function ViewPicker(
    {
        searchChanged,
    }: ViewPickerProps)
{
    const [viewKey, setViewKey] = useSelectedView();
    const { branchProps, selectedRepoKey } = useRepositoryData();

    return (
        <Container sx={{
            mt: 4
        }}
        >
            <Stack
                direction={{
                    xs: "column",
                    sm: "column",
                    md: "row"
                }}
                spacing={2}
                justifyContent="space-between"
            >
                <div>
                    <ToggleButtonGroup
                        color="primary"
                        value={viewKey}
                        exclusive
                        size="small"
                        onChange={(_, value) => value && setViewKey(value)}
                    >
                        <ToggleButton value="localizations">Localizations</ToggleButton>
                        <ToggleButton value="export">Export</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div>
                    {viewKey === "localizations" &&
                        <Stack
                            direction="row"
                            spacing={2}
                        >
                            <RepositorySelector />
                            <BranchAutocomplete
                                {...branchProps[selectedRepoKey]}
                            />
                            <LanguageCodeSelector />
                        </Stack>}
                    {viewKey === "export" && <ExportDropdown />}
                </div>
                <Searchbar searchChanged={searchChanged} />
            </Stack>
        </Container>
    );
}
