import { AppBar, Box, Button, Container, Toolbar, Typography } from "@mui/material";
import { useMemo } from "react";

import { Logo } from "../assets/PanoptoLogo";
import { useAuth } from "../services/auth/AuthContext";

export function Navbar()
{
    const { userInfo, signOut } = useAuth();

    const email = useMemo(() =>
    {
        return userInfo?.email ?? "";
    }, [userInfo]);

    return (
        <AppBar
            position="static"
            color="secondary"
        >
            <Container maxWidth="xl">
                <Toolbar
                    disableGutters
                    sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <Box
                        height="64px"
                        padding="12px"
                    >
                        <Logo />
                    </Box>
                    <Box
                        flexDirection="row"
                        display="flex"
                        alignItems="center"
                    >
                        <Typography
                            variant="h5"
                            mr="12px"
                            sx={{ display: { xs: "none", sm: "block" } }}
                        >
                            {email}
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={signOut}
                            sx={{ whiteSpace: "nowrap" }}
                        >
                            Sign Out
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
