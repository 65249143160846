import { saveAs } from "file-saver";
import JSZip from "jszip";
import { groupBy, keyBy } from "lodash";

import { LocalizationItem } from "../../models/LocalizationItem";
import { dexieHelper, LocalizationDexie } from "../database/dexie";
import { fileNameFromPath, LANGUAGE_MAP } from "./helpers/fileScheme";
import { generateSummary } from "./helpers/generateSummary";
import { formatXML, XML_BASE } from "./helpers/xmlFunctions";

const REVIEW_PREFIX = "nr:";

export async function downloadFiles(
    name: string,
    items: LocalizationItem[],
    lastExport?: LocalizationDexie)
{
    // '/' is not allowed in file names. JSZip will think its a folder
    name = name.replaceAll("/", "-");

    const zip = new JSZip();
    const rootFolder = zip.folder(name);

    // Maps from langCode -> LocalizationItem[]
    const itemsByLanguage = groupBy(items, (item) => item.langCode);

    // Export only contains "en" items, we just need to map path -> LocalizationItem[]
    // A lastExport of null means we aren't comparing previous exports,
    // instead we are downloading for GitHub
    const lastExportByPath = lastExport
        ? groupBy(await dexieHelper(lastExport).validItems(), (item) => item.path)
        : null;

    for (const langCode of Object.keys(itemsByLanguage))
    {
        const itemsForLanguage = itemsByLanguage[langCode];

        // We now have a map from filePath -> LocalizationItem[]. (Also grouped by language)
        const itemsByFile = groupBy(itemsForLanguage, (item) => item.path);
        const languageFolder = rootFolder?.folder(LANGUAGE_MAP[langCode]);
        if (!languageFolder || !rootFolder)
        {
            throw new Error(`Could not create folder for language ${langCode}`);
        }

        // For a given language, process every file and add it to the correct folder
        for (const filePath of Object.keys(itemsByFile))
        {
            const newestItems = itemsByFile[filePath];
            const path = fileNameFromPath({
                langCode,
                path: filePath,
                exportForGh: !lastExportByPath
            });

            languageFolder.file(path, filePath.endsWith(".json")
                ? buildJsonFile(newestItems)
                : buildResxFile(newestItems));

            // Don't include English_NewAndChanged when downloading
            // files for GitHub (when lastExport is null)
            if (lastExportByPath && langCode === "en")
            {
                const previousItems = lastExportByPath[filePath] ?? [];
                generateEnglishDiff(newestItems, previousItems, rootFolder);
            }
        }
    }

    // Include StringChanges.txt summary file when downloading for translation
    if (lastExportByPath && rootFolder)
    {
        rootFolder.file("StringChanges.txt", generateSummary(items, lastExportByPath));
    }

    const blob = await zip.generateAsync({ type: "blob" });
    saveAs(blob, name + ".zip");
    return zip;
}

export function generateEnglishDiff(
    newest: LocalizationItem[],
    previous: LocalizationItem[],
    root: JSZip)
{
    const previousByKey = keyBy(previous, "key");
    const newestByKey = keyBy(newest, "key");

    // filteredKeys only contains items with new keys or changed values
    const filteredKeys = Object.keys(newestByKey).filter(key =>
        !previousByKey[key]
        || previousByKey[key].value !== newestByKey[key].value
    );

    const filteredItems = filteredKeys.map(key => newestByKey[key]);

    let file: string | null = null;
    // Only make the file if we have at least one item that was new/changed
    if (filteredItems.length > 0)
    {
        const path = filteredItems[0].path;

        file = path.endsWith(".json")
            ? buildJsonFile(filteredItems)
            : buildResxFile(filteredItems);

        root.file("English_NewAndChanged/" +
            fileNameFromPath({ path, langCode: "en" }), file);
    }
    return file;
}

export function buildJsonFile(items: LocalizationItem[]): string
{
    const dictionary: { [key: string]: string; } = {};
    // Default to an empty string when the value is empty
    // (DDB doesn't store the field for empty strings)
    items.forEach(item => dictionary[item.key] = item.value ?? "");

    // Indent with 4 spaces
    return JSON.stringify(dictionary, null, 4);
}

export function buildResxFile(items: LocalizationItem[]): string
{
    const doc = new DOMParser().parseFromString(XML_BASE, "text/xml");
    const root = doc.getElementsByTagName("root")[0];

    for (const item of items)
    {
        // name attribute on the <data> tag is the key
        const data = doc.createElement("data");
        data.setAttribute("name", item.key);
        data.setAttribute("xml:space", "preserve");

        const value = doc.createElement("value");
        value.textContent = item.value ?? "";
        data.appendChild(value);

        if (item.comment || item.needsReview)
        {
            const comment = doc.createElement("comment");
            let commentText = item.comment ?? "";

            if (item.needsReview)
            {
                // Such we don't have an extra space when the comment is <comment>nr:</comment>
                commentText = commentText
                    ? `${REVIEW_PREFIX} ${commentText.trimStart()}`
                    : REVIEW_PREFIX;
            }

            comment.textContent = commentText;
            data.appendChild(comment);
        }

        root.appendChild(data);
    }
    return formatXML(doc);
}
