import Dexie, { Table } from "dexie";

import { LocalizationItem } from "../../models/LocalizationItem";
import { extractFolderPath } from "../download-manager/helpers/fileScheme";

// https://dexie.org/docs/Typescript
export class LocalizationDexie extends Dexie
{
    localizations!: Table<LocalizationItem>;

    constructor(repoBranch: string)
    {
        super(repoBranch);
        this.version(1).stores({
            // [path+key] is the primary key
            // index langCode for filtering
            // index lastSync to retrieve the most recent items
            localizations: "[path+key],langCode,lastSync"
        });
    }
}

export const isPlaceholderItem = (item: LocalizationItem): boolean => !item.repoBranch || !item.pathKey;

export const dexieHelper = (db: LocalizationDexie) =>
{
    async function lastSync(): Promise<string | null>
    {
        const mostRecentItems = await db.localizations
            .orderBy("lastSync")
            .reverse()
            .limit(1)
            .toArray();
        return mostRecentItems.length ? mostRecentItems[0].lastSync : null;
    }

    async function filter(langCode: string): Promise<LocalizationItem[]>
    {
        return await db.localizations
            .where({ langCode })
            .filter(i => i.isDeleted !== 1)
            .toArray();
    }

    async function validItems(): Promise<LocalizationItem[]>
    {
        return await db.localizations
            .filter(i => i.isDeleted !== 1)
            .toArray();
    }

    async function missingItems(containedItems: LocalizationItem[]): Promise<LocalizationItem[]>
    {
        const missingItems: LocalizationItem[] = [];
        const allItems = await filter("en");

        const containedItemSet = new Set<string>();
        containedItems.forEach(item =>
        {
            // We identify whether keys match based on folderPath + key
            // If we instead used path + key, then we wouldn't be able to check across files
            // since src/en.json and src/de.json wouldn't be considered the same file 
            containedItemSet.add(extractFolderPath(item.path) + item.key);
        });

        allItems.forEach(item =>
        {
            if (!containedItemSet.has(extractFolderPath(item.path) + item.key))
            {
                missingItems.push({
                    ...item,
                    repoBranch: "",
                    pathKey: "",
                    value: "",
                    comment: "",
                });
            }
        });

        return missingItems;
    }

    return {
        lastSync,
        filter,
        validItems,
        missingItems
    };
};
