import { useQuery } from "@tanstack/react-query";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import React, { Dispatch, useCallback, useContext, useMemo, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import { AppRoutes } from "../../App";
import { AuthRequest } from "../api-routes/localizations";
import { APPROVER, EDITOR, EXPORTER, PULLER, VIEWER } from "./AuthorizationGroups";

// Check in the background at this interval to
// verify if the user is still signed in
const REVALIDATE_SESSION_INTERVAL_MINUTES = 5;

interface AuthProps
{
    status: "unknown" | "authenticated" | "unauthenticated",
    user: CognitoUser | null,
}

const authReducer = (state: AuthProps, user: CognitoUser | null): AuthProps =>
{
    return {
        status: user ? "authenticated" : "unauthenticated",
        user
    };
};

export const AuthContext = React.createContext({} as [AuthProps, Dispatch<CognitoUser | null>]);
export const { Provider } = AuthContext;
export function AuthProvider({ children }: { children: React.ReactNode; })
{
    const [auth, setAuth] = useReducer(authReducer, {
        status: "unknown",
        user: null
    });

    return (
        <Provider
            value={[auth, setAuth]}
        >
            {children}
        </Provider>
    );
}


/**
 * useAuth is used in components protected by AuthenticatedRoute
 * which guarantees we will always have a valid user object
 * @returns methods to get the current user, jwt token, and sign out
 */
export const useAuth = () =>
{
    const navigate = useNavigate();
    const [auth, setAuth] = useContext(AuthContext);

    const signOut = useCallback(async () =>
    {
        await Auth.signOut();
        setAuth(null);
        navigate(AppRoutes.SignIn.path);
    }, [navigate, setAuth]);

    // This query serves two purposes:
    // Grab the user attributes object (which contains the user's email address to display)
    // Verify that the user session is valid
    const { data: userInfo } = useQuery(["userInfo"], async () =>
    {
        // bypassing the cache to verify the user session is valid
        // Cognito will autometically redirect to signin if the session has expired
        try
        {
            return (await Auth.currentAuthenticatedUser({ bypassCache: true }))?.attributes;
        }
        catch
        {
            // If for some reason the refresh fails, sign out the user
            signOut();
        }
    }, {
        refetchInterval: 1000 * 60 * REVALIDATE_SESSION_INTERVAL_MINUTES,
        refetchOnMount: false
    });

    const getRefreshedJwt = useCallback(async () =>
    {
        const session = await Auth.currentSession();
        return session.getIdToken().getJwtToken();
    }, []);

    const authClient = useCallback(
        async () => new AuthRequest(await getRefreshedJwt() ?? ""),
        [getRefreshedJwt]
    );

    const groups = useMemo(() =>
    {
        const payload = auth.user?.getSignInUserSession()?.getIdToken()?.payload;
        const groups: string[] = payload
            ? (payload["cognito:groups"] ?? [])
            : [];

        return {
            isEditor: groups.includes(EDITOR),
            isApprover: groups.includes(APPROVER),
            isExporter: groups.includes(EXPORTER),
            isPuller: groups.includes(PULLER),
            isViewer: groups.includes(VIEWER)
        };
    }, [auth.user]);

    // If user is null, or if a refreshed JWT cannot be generated
    // the user is automatically signed out and redirected to the sign in page
    return {
        user: auth.user,
        userInfo,
        signOut,
        getRefreshedJwt,
        authClient,
        groups
    };
};
