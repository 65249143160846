import { ListChildComponentProps } from "react-window";

export function RenderRow(props: ListChildComponentProps)
{
    const { data, index, style } = props;
    const inlineStyle = {
        ...style,
        top: style.top,
    };

    return (
        <div
            style={inlineStyle}
        >
            {data[index]}
        </div>
    );
}
