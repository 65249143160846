import { AuthRequest } from "../api-routes/localizations";
import { LocalizationDexie } from "../database/dexie";

export const getTableName = (id: number) => "export_" + id;

// Loads the export from the IDB
// If IDB is empty then will attempt to fetch from server and load into IDB
export const loadExport = async (client: AuthRequest, id: number): Promise<LocalizationDexie> =>
{
    const table: LocalizationDexie = new LocalizationDexie(getTableName(id));

    // Nothing has been downloaded yet, grab the latest from the server
    if (await table.localizations.count() === 0)
    {
        const localizations = await client.getExport({ exportId: id.toString() });

        // its possible localizations.length === 0 if no exports have been made
        if (localizations.length)
        {
            // Save to dexie
            await table.transaction("readwrite", table.localizations,
                async () => await table.localizations.bulkPut(localizations));
        }
    }

    return table;
};
