import { Container, LinearProgress, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { AppRoutes } from "../../App";
import { Navbar } from "../../components/Navbar";
import { AuthContext, useAuth } from "./AuthContext";

/**
 * To access the child route user must be signed in
 */

export function AuthenticatedRoute()
{
    const [auth, setAuth] = useContext(AuthContext);
    const { groups } = useAuth();

    useQuery(["initialAuth", auth.status], async () =>
    {
        let user: CognitoUser | null = null;
        try
        {
            // When not in a try/catch, Auth.currentAuthenticatedUser will not throw an exception
            // and will instead hang forever
            user = await Auth.currentAuthenticatedUser();
        }
        finally
        {
            setAuth(user);
        }
    }, { enabled: auth.status === "unknown" });

    switch (auth.status)
    {
        case "unknown":
            return <LinearProgress />;

        case "authenticated":
            return groups.isViewer
                ? <Outlet />
                : <Unauthorized />;

        case "unauthenticated":
            return <Navigate to={AppRoutes.SignIn.path} />;
    }
}

function Unauthorized()
{
    return (
        <>
            <Navbar />
            <Container sx={{ mt: 4 }}>
                <Typography
                    variant="body1"
                    textAlign="center"
                >
                    The currently authenticated user is not permitted to view this page.
                    <br />
                    If permissions have been updated, retry signing in.
                </Typography>
            </Container>
        </>
    );
}
