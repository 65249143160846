import React from "react";
import { VariableSizeList } from "react-window";

import { RenderRow } from "./RenderRow";

// Most of this code comes directly from MUI
// See: https://mui.com/material-ui/react-autocomplete/#virtualization

export const VirtualizedList = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref)
{
    const { children, ...other } = props;
    const itemData: React.ReactChild[] = [];

    (children as React.ReactChild[]).forEach(
        (item: React.ReactChild & { children?: React.ReactChild[]; }) =>
        {
            itemData.push(item);
            itemData.push(...(item.children || []));
        },
    );

    const itemCount = itemData.length;
    const itemSize = 41;
    const maxHeight = 350;

    const getHeight = () =>
    {
        return Math.min(
            // Round to nearest whole number that is divisible by itemSize
            Math.ceil(maxHeight / itemSize) * itemSize,
            itemSize * itemCount);
    };

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight()}
                    width="100%"
                    outerElementType={OuterElementType}
                    itemSize={() => itemSize}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {RenderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) =>
{
    const outerProps = React.useContext(OuterElementContext);
    return (
        <div
            ref={ref}
            {...props}
            {...outerProps}
        />
    );
});
