import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

import { useAuth } from "../auth/AuthContext";
import { LocalizationDexie } from "../database/dexie";
import { loadExport } from "../persistence/exports";
import { mergeUpdatesWithLocalDb } from "../persistence/synchronize";
import { useSelectedExport, useSelectedView } from "./localStorageHooks";
import { useRepositoryData } from "./repositoryData";

export const LOCALIZATIONS_QUERY = "localizations";

// Only show notifications when the number of
// localizations downloaded >= this threshold
const NOTIFICATION_CUTOFF = 100;

interface FetchLatestParams
{
    setShouldPing: (shouldPing: boolean) => void;
    setTable: (table?: LocalizationDexie) => void;
}

export function useFetchLatest({ setShouldPing, setTable }: FetchLatestParams)
{
    const [selectedExport] = useSelectedExport();
    const [viewKey] = useSelectedView();

    const { selectedRepoKey, selectedRepoState } = useRepositoryData();
    const { authClient } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const notifyProgress = useCallback((count: number) =>
    {
        if (count >= NOTIFICATION_CUTOFF)
        {
            enqueueSnackbar(`Synced ${count} localizations`);
        }
    }, [enqueueSnackbar]);

    // Automatically retrieves data from server or IndexedDB when available
    // Sets the tables array to Dexie tables representing the data that should be displayed on the UI
    const {
        isFetching,
        isRefetching,
        refetch
    } = useQuery([
        LOCALIZATIONS_QUERY,
        selectedRepoKey,
        selectedRepoState.branch,
        selectedExport,
        viewKey
    ], async ({ signal }) =>
    {
        setShouldPing(false);
        const client = await authClient();

        if (viewKey === "export")
        {
            const id = selectedExport?.exportId;

            if (id !== undefined)
            {
                const table = await loadExport(client, id);
                if (!signal?.aborted)
                {
                    setTable({
                        ...table
                    });
                }
            }
        }
        else
        {
            try
            {
                await mergeUpdatesWithLocalDb({
                    client,
                    signal,
                    repoState: selectedRepoState,
                    onProgressDownload: (count) =>
                    {
                        setTable({
                            ...selectedRepoState.table
                        });
                        notifyProgress(count);
                    }
                });
                setShouldPing(true);
            }
            catch (error: unknown)
            {
                // If the download is aborted, we don't want to show an error / ping for updates
                if (error instanceof Error && error.name !== "AbortError")
                {
                    enqueueSnackbar("An error occurred while downloading localizations", { variant: "error" });
                    setShouldPing(true);
                }
                else
                {
                    // We don't recognize the error. (Will auto-retry 3 times)
                    // Most likely scenario is the lambda closed mid-download and
                    // we need to retry to spawn a new instance
                    throw error;
                }
            }
        }
    });

    return {
        isLoading: isFetching || isRefetching,
        refetch
    };
}
