import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { LANGUAGE_MAP } from "../../../services/download-manager/helpers/fileScheme";
import { useSelectedLangCode } from "../../../services/hooks/localStorageHooks";

export const LANG_CODES = Object.keys(LANGUAGE_MAP);

export function LanguageCodeSelector()
{
    const [langCode, setLangCode] = useSelectedLangCode();

    return (
        <FormControl
            sx={{ m: 1, minWidth: 120 }}
            size="small"
        >
            <InputLabel>Language Code</InputLabel>
            <Select
                value={langCode}
                label="Language Code"
                onChange={(event) => setLangCode(event.target.value)}
                MenuProps={{
                    style: {
                        maxHeight: "400px"
                    }
                }}
            >
                {LANG_CODES.map(lang =>
                    <MenuItem
                        key={lang}
                        value={lang}
                    >
                        {lang}
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
}
