import { Dictionary, groupBy, keyBy } from "lodash";

import { LocalizationItem } from "../../../models/LocalizationItem";
import { fileNameFromPath } from "./fileScheme";

const FILE_START = `This file summarizes the set of new and changed strings in Panopto's latest release.
When translating any given .resx file, please review this list to confirm that the current translation
of any listed 'Changed string value' still makes sense with respect to the English string.
`;

const FILE_DIVIDER = `

========================================================

`;

const FILE_END_DIVIDER = `

===========

Totals
`;

function countWords(items: LocalizationItem[]): number
{
    return items.reduce(
        (prev, curr) => prev + (curr.value || "").split(" ").length,
        0);
}

export function generateSummary(
    items: LocalizationItem[],
    lastExportByPath: Dictionary<LocalizationItem[]>): string
{
    // Group English resources by path to match last export for comparison
    const itemsByPath = groupBy(
        items.filter((item) => item.langCode === "en"),
        (item) => item.path
    );

    // Track some statistics across all resource files
    let totalAdded = 0;
    let totalChanged = 0;
    let previousTotalStringCount = 0;
    let totalStringCount = 0;
    let previousTotalWordCount = 0;
    let totalWordCount = 0;

    // Build summary file string contents
    let summaryString = FILE_START;

    for (const path of Object.keys(itemsByPath))
    {
        // Delineate a section for the new file and add basic stats
        summaryString += FILE_DIVIDER;
        summaryString += `Resource file: ${fileNameFromPath({ path, langCode: "en" })}` + "\n";
        summaryString += `Previous string count: ${(lastExportByPath[path] || []).length}` + "\n";
        summaryString += `New string count: ${itemsByPath[path].length}` + "\n\n\n";

        const previousByKey = keyBy(lastExportByPath[path] || [], "key");
        const currentByKey = keyBy(itemsByPath[path], "key");

        // Include section about updated strings
        const changedKeys = Object.keys(currentByKey).filter((key) =>
            previousByKey[key] && previousByKey[key].value !== currentByKey[key].value);

        summaryString += ` --- Changed string values: ${changedKeys.length} ---` + "\n";

        for (const changedKey of changedKeys)
        {
            summaryString += changedKey + "\n";
        }

        // Include section about new strings
        const addedKeys = Object.keys(currentByKey).filter((key) => !previousByKey[key]);

        summaryString += "\n\n" + ` --- New strings: ${addedKeys.length} ---` + "\n";

        for (const addedKey of addedKeys)
        {
            summaryString += addedKey + "\n";
        }

        summaryString += "\n\n";

        // Include previous and current word counts
        const previousWordCount = countWords(lastExportByPath[path] || []);
        const currentWordCount = countWords(itemsByPath[path]);
        summaryString += `Previous word count: ${previousWordCount}` + "\n";
        summaryString += `Current word count: ${currentWordCount}` + "\n";

        // Update running totals
        totalAdded += addedKeys.length;
        totalChanged += changedKeys.length;
        previousTotalStringCount += (lastExportByPath[path] || []).length;
        totalStringCount += itemsByPath[path].length;
        previousTotalWordCount += previousWordCount;
        totalWordCount += currentWordCount;
    }

    summaryString += FILE_END_DIVIDER;
    summaryString += `New strings: ${totalAdded}` + "\n";
    summaryString += `Deleted strings: ${previousTotalStringCount + totalAdded - totalStringCount}` + "\n";
    summaryString += `Changed strings: ${totalChanged}` + "\n";
    summaryString += `Previous word count (all files): ${previousTotalWordCount}` + "\n";
    summaryString += `Current word count (all files): ${totalWordCount}` + "\n";
    summaryString += `Previous string count: ${previousTotalStringCount}` + "\n";
    summaryString += `Current string count: ${totalStringCount}` + "\n";

    return summaryString;
}
