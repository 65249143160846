import useLocalStorage from "@rehooks/local-storage";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { BranchAutocompleteProps } from "../../components/table/BranchAutocomplete";
import { CORE_REPO, MOBILE_REPO } from "../../constants";
import { useAuth } from "../auth/AuthContext";
import { LocalizationDexie } from "../database/dexie";

export interface RepoState
{
    branch: string;
    repoName: string;
    table: LocalizationDexie;
    label: string;
}

export const BRANCH_QUERY = "branches";

export type Repository = "core" | "mobile";

export function useRepositoryData()
{
    const STALE_TIME_MINUTES = 60;

    /**
     * Selected branches are saved in local storage
     */
    const [coreBranch, setCoreBranch] = useLocalStorage("coreBranch", "develop");
    const [mobileBranch, setMobileBranch] = useLocalStorage("mobileBranch", "current");

    const { authClient } = useAuth();

    const { data } = useQuery([BRANCH_QUERY], async () =>
    {
        return (await authClient()).getBranches();
    }, { staleTime: STALE_TIME_MINUTES * 60 * 1000 });

    // Used only to keep track of when a branch has been updated
    // Causes an effect to run trigger fetching from IndexedDB
    const repoStates: Record<Repository, RepoState> = useMemo(() => ({
        core: {
            label: "Panopto Core",
            branch: coreBranch,
            repoName: CORE_REPO,
            table: new LocalizationDexie(`${CORE_REPO}_${coreBranch}`)
        },
        mobile: {
            label: "Mobile App",
            branch: mobileBranch,
            repoName: MOBILE_REPO,
            table: new LocalizationDexie(`${MOBILE_REPO}_${mobileBranch}`)
        }
    }), [coreBranch, mobileBranch]);

    const [selectedRepoKey, setSelectedRepoKey] = useLocalStorage<Repository>("selectedRepo", "core");

    // Passed to the BranchAutocomplete component
    const branchProps: Record<Repository, BranchAutocompleteProps> = useMemo(() => ({
        core: {
            branches: [coreBranch, ...(data?.coreBranches ?? [])],
            setBranch: setCoreBranch,
            ...repoStates.core,
        },
        mobile: {
            branches: [mobileBranch, ...(data?.mobileBranches ?? [])],
            setBranch: setMobileBranch,
            ...repoStates.mobile,
        }
    }), [coreBranch, data, mobileBranch, repoStates, setCoreBranch, setMobileBranch]);

    return {
        repoStates,
        branchProps,
        selectedRepoKey,
        setSelectedRepoKey,
        selectedRepoState: repoStates[selectedRepoKey]
    };
}
