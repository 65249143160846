import { FilterOptionsState, ListItem, ListItemText, Typography } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

import { VirtualizedList } from "./list-virtualizer/VirtualizedList";

const filter = createFilterOptions<string>({
    matchFrom: "start"
});

export interface BranchAutocompleteProps
{
    label: string;
    branch: string;
    branches: string[];
    setBranch: (branches: string) => void;
}

/**
 * BranchAutocomplete is the dropdown which allows for selecting branches on the main page
 */
export function BranchAutocomplete({ label, branch, branches, setBranch }: BranchAutocompleteProps)
{
    const [open, toggleOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState("");

    const handleClose = () => toggleOpen(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) =>
    {
        event.preventDefault();
        handleClose();
    };

    const handleConfirm = () =>
    {
        setBranch(dialogValue);
    };

    const filterBranches = (options: string[], params: FilterOptionsState<string>) =>
    {
        const filtered = filter(options, params);
        if (params.inputValue !== "" && !filtered.includes(params.inputValue))
        {
            filtered.push(params.inputValue);
        }
        return filtered;
    };

    return (
        <>
            <Autocomplete
                value={branch}
                onChange={(_, newValue) =>
                {
                    if (newValue)
                    {
                        setDialogValue(newValue);
                        toggleOpen(true);
                    }
                }}
                filterOptions={filterBranches}
                // Skip the first option as that is already selected. (And we don't want to allow deleting it)
                options={branches.slice(1)}
                getOptionLabel={(option) => option}
                selectOnFocus
                openOnFocus
                clearOnBlur
                blurOnSelect
                handleHomeEndKeys
                ListboxComponent={VirtualizedList}
                renderOption={(props, option) =>
                    <BranchListItem
                        props={props}
                        option={option}
                        key={option}
                    />
                }
                sx={{ width: 200 }}
                freeSolo
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label="Branch"
                    />
                }
                size="small"
            />
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle fontSize={18}>
                        Switch to branch
                        <b>{" " + dialogValue}</b>
                        ?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            If no localizations appear make sure to pull changes.
                            (Initial process can take a few minutes)
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            type="submit"
                            onClick={handleConfirm}
                        >
                            Continue
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}

interface BranchListItemProps
{
    props: React.HTMLAttributes<HTMLLIElement>;
    option: string;
}

function BranchListItem({ props, option }: BranchListItemProps)
{
    return (
        <ListItem
            {...props}
        >
            <ListItemText>
                <Typography
                    noWrap
                    fontSize={14}
                >
                    {option}
                </Typography>
            </ListItemText>
        </ListItem>
    );
}
