import { isEmpty } from "lodash";
import omitBy from "lodash/omitBy";
import urlJoin from "url-join";

import { WEB_API_URL } from "../../constants";
import { LocalizationItem } from "../../models/LocalizationItem";
import
{
    AuthRequestParams,
    GenerateExportParams,
    GetBranchResponse,
    GetExportParams,
    GetLatestParams,
    GetLatestResponse,
    RepoParams,
    ReviewStatusParams
} from "../../models/Queries";

/**
 * Creates an authenticated request by adding the jwt as an auth bearer
 * Any of the returned routes are available to use
 */

export class AuthRequest
{
    constructor(private jwt: string)
    {
    }

    private request = async ({ method, url, body }: AuthRequestParams) =>
    {
        const headers = new Headers({ Authorization: `Bearer ${this.jwt}` });

        const requestOptions: Record<string, unknown> = {
            method,
            headers,
        };

        if (body)
        {
            headers.set("Content-Type", "application/json");
            requestOptions.body = JSON.stringify(body);
        }

        return this.handleResponse(await fetch(url, requestOptions));
    };

    private handleResponse = (response: Response) =>
        response.text().then(text =>
        {
            return response.ok
                ? text && JSON.parse(text)
                : Promise.reject(response);
        });

    // ------------------------------------------------
    // ---------------------ROUTES---------------------
    // ------------------------------------------------

    // omitBy skips any null, undefined, "", []. We don't want to include these as query params

    getLatest = async (params: GetLatestParams): Promise<GetLatestResponse> =>
    {
        return await this.request({
            method: "GET",
            url: urlJoin(WEB_API_URL, "localizations", "latest?") + new URLSearchParams({
                ...omitBy(params, isEmpty)
            })
        });
    };

    syncChanges = async (params: RepoParams) =>
    {
        return await this.request({
            method: "GET",
            url: urlJoin(WEB_API_URL, "localizations", "sync?") + new URLSearchParams({
                ...omitBy(params, isEmpty)
            })
        });
    };

    putLocalization = async (item: LocalizationItem) =>
    {
        return await this.request({
            method: "PUT",
            url: urlJoin(WEB_API_URL, "localizations"),
            body: item
        });
    };

    getExport = async (params: GetExportParams): Promise<LocalizationItem[]> =>
    {
        return await this.request({
            method: "GET",
            url: urlJoin(WEB_API_URL, "localizations", "export?") + new URLSearchParams({
                ...omitBy(params, isEmpty)
            }),
        });
    };

    getRecentExports = async (): Promise<LocalizationItem[]> =>
    {
        return await this.request({
            method: "GET",
            url: urlJoin(WEB_API_URL, "localizations", "exports")
        });
    };

    markExport = async (exportParams: GenerateExportParams, repoParams: RepoParams[]): Promise<LocalizationItem[]> =>
    {
        return await this.request({
            method: "POST",
            url: urlJoin(WEB_API_URL, "localizations", "export?") + new URLSearchParams({
                ...omitBy(exportParams, isEmpty)
            }),
            body: repoParams
        });
    };

    getBranches = async (): Promise<GetBranchResponse> =>
    {
        return await this.request({
            method: "GET",
            url: urlJoin(WEB_API_URL, "localizations", "branches")
        });
    };

    setReview = async (params: ReviewStatusParams): Promise<void> =>
    {
        return await this.request({
            method: "PUT",
            url: urlJoin(WEB_API_URL, "localizations", "review?") + new URLSearchParams({
                ...omitBy(params, isEmpty)
            }),
        });
    };
}
