import { Box, CircularProgress, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AppRoutes } from "../App";
import { AuthContext } from "../services/auth/AuthContext";

export function SignIn()
{
    const [auth] = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() =>
    {
        // Will automatically redirect user to the home page
        // if they are already signed in
        if (auth.status === "authenticated")
        {
            navigate(AppRoutes.Home.path);
        }
        // Otherwise we immediately redirect to AWS's Hosted SSO page
        else
        {
            Auth.federatedSignIn();
        }
    }, [navigate, auth]);

    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mt={4}
        >
            <Typography variant="body1">
                Redirecting to SSO Sign-In...
            </Typography>
            <CircularProgress
                size={18}
                sx={{ ml: 2 }}
            />
        </Box>
    );
}
