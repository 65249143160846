import { Tooltip, Typography } from "@mui/material";

export function HistoryInfo({ content, date }: { content: string, date?: string; })
{
    return (
        // Empty string won't be displayed as a tooltip
        <Tooltip
            placement="top"
            title={date ? new Date(date).toLocaleString() : ""}
        >
            <Typography
                variant="body2"
                width="100%"
                align="center"
            >
                {content ? content.split("@")[0] : ""}
            </Typography>
        </Tooltip>
    );
}
