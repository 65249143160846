import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { SnackbarKey, useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";

import { useAuth } from "../auth/AuthContext";
import { dexieHelper } from "../database/dexie";
import { useRepositoryData } from "./repositoryData";

interface UpdateAlertParams
{
    shouldPing: boolean;
    handleRefresh: () => void;
}

const PING_LATEST_INTERVAL_SECONDS = 30;

export const UPDATE_PING_QUERY = "updatePing";

export function useUpdateAlert({ shouldPing, handleRefresh }: UpdateAlertParams)
{
    const { authClient } = useAuth();
    const { selectedRepoKey, selectedRepoState } = useRepositoryData();

    const [finished, setFinished] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const snackBarId = useRef<SnackbarKey | undefined>();

    const refreshAction = useCallback((snackbarId: SnackbarKey) =>
    {
        snackBarId.current = snackbarId;
        return (
            <>
                <IconButton
                    size="small"
                    onClick={() =>
                    {
                        handleRefresh();
                        closeSnackbar(snackbarId);
                    }}
                    color="secondary"
                >
                    <RefreshIcon fontSize="small" />
                </IconButton>
                <IconButton
                    onClick={() => closeSnackbar(snackbarId)}
                    size="small"
                    color="secondary"
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </>
        );
    }, [closeSnackbar, handleRefresh]);

    useQuery([
        UPDATE_PING_QUERY,
        shouldPing,
        finished,
        selectedRepoKey,
        selectedRepoState.branch
    ], async ({ signal }) =>
    {
        await new Promise(resolve => setTimeout(resolve, PING_LATEST_INTERVAL_SECONDS * 1000));
        if (signal?.aborted)
        {
            return;
        }

        const client = await authClient();
        const response = await client.getLatest({
            repo: selectedRepoState.repoName,
            branch: selectedRepoState.branch,
            limit: "1",
            lastSync: await dexieHelper(selectedRepoState.table).lastSync()
        });

        // An update is available, break from the loop
        if (response.items.length)
        {
            enqueueSnackbar("New changes available", {
                variant: "info",
                persist: true,
                action: refreshAction
            });
            setFinished(true);
        }
    }, {
        enabled: shouldPing && !finished,
        refetchInterval: 1
    });

    useEffect(() =>
    {
        setFinished(false);
    }, [selectedRepoState, shouldPing]);

    // Automatically closes the alert when it is out of date
    useEffect(() =>
    {
        if (snackBarId.current && !shouldPing)
        {
            closeSnackbar(snackBarId.current);
        }
    }, [shouldPing, closeSnackbar]);
}
