import { Button, CircularProgress } from "@mui/material";

interface LoadingButtonProps
{
    loading: boolean;
    onClick: () => void;
    label: string;
    disabled?: boolean;
}

export function LoadingButton({ loading, onClick, label, disabled }: LoadingButtonProps)
{
    return (
        <Button
            onClick={onClick}
            endIcon={loading ? <CircularProgress size={16} /> : null}
            disabled={loading || disabled}
        >
            {label}
        </Button>
    );
}
