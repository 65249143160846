import { Container, LinearProgress } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";

import { LocalizationItem } from "../../models/LocalizationItem";
import { useAuth } from "../../services/auth/AuthContext";
import { LOCALIZATIONS_QUERY } from "../../services/hooks/fetchLatest";
import { useSelectedLangCode, useSelectedView } from "../../services/hooks/localStorageHooks";
import { NeedsReviewCheckbox, RowTextField } from "./cell-items/EditableCells";
import { HistoryInfo } from "./cell-items/HistoryInfo";
import { FilterOptions, testFilter } from "./filters/FilterButtons";
import { NoRowsOverlay } from "./NoRowsOverlay";

interface LocalizationsViewerProps
{
    data?: LocalizationItem[] | null;
    loading: boolean;
    editable: boolean;
    search: string;
    filters: FilterOptions[];
}

export function LocalizationsViewer({ data: unfilteredData, loading, editable, search, filters }: LocalizationsViewerProps)
{
    const [data, setData] = useState<LocalizationItem[] | undefined>();
    const [langCode] = useSelectedLangCode();
    const [selectedView] = useSelectedView();

    const queryClient = useQueryClient();
    const { authClient, groups } = useAuth();

    const { mutate: onShouldSave } = useMutation(async (item: LocalizationItem) =>
    {
        const client = await authClient();

        await client.putLocalization({
            ...item,
        });

        queryClient.invalidateQueries([LOCALIZATIONS_QUERY]);
    });

    const { mutate: onSetReview } = useMutation(async (item: LocalizationItem) =>
    {
        const client = await authClient();

        await client.setReview({
            needsReview: item.needsReview.toString(),
            pathKey: item.pathKey
        });

        queryClient.invalidateQueries([LOCALIZATIONS_QUERY]);
    });

    useEffect(() =>
    {
        if (unfilteredData)
        {
            setData(unfilteredData.filter(item =>
                testFilter(
                    item,
                    // don't apply filters on export viewer
                    selectedView === "localizations" ? filters : [],
                    search
                )
            ));
        }
    }, [unfilteredData, search, filters, selectedView]);

    const columns = useMemo(() =>
    {
        const cols: GridColDef[] = [
            {
                headerName: "Repository",
                field: "repo",
                flex: 0.4,
                minWidth: 110,
            },
            {
                headerName: "Key",
                field: "key",
                flex: 0.8,
                minWidth: 300
            },
            {
                headerName: "Value",
                field: "value",
                flex: 1,
                minWidth: 250,
                renderCell: (params) =>
                    <RowTextField
                        params={params}
                        onShouldSave={onShouldSave}
                        disabled={!editable || !groups.isEditor}
                    />
            },
            {
                headerName: "Comment",
                field: "comment",
                flex: 1,
                minWidth: 110,
                renderCell: (params) =>
                    <RowTextField
                        params={params}
                        onShouldSave={onShouldSave}
                        disabled={!editable || !groups.isEditor}
                    />
            },
        ];

        // Only show needs review column for english items
        if (langCode.toLowerCase() === "en" && selectedView === "localizations")
        {
            cols.push({
                headerName: "Reviewed",
                field: "needsReview",
                align: "center",
                renderCell: (params) =>
                    <NeedsReviewCheckbox
                        params={params}
                        onShouldSave={onSetReview}
                        disabled={!editable || !groups.isApprover}
                    />,
                width: 90,
            });
        }

        if (selectedView === "localizations")
        {
            cols.push({
                headerName: "Last Author",
                field: "lastAuthor",
                flex: 0.3,
                minWidth: 100,
                renderCell: (params) => (
                    <HistoryInfo
                        content={params.value}
                        date={params.row.lastModified}
                    />
                )
            });

            cols.push({
                headerName: "Last Reviewer",
                field: "lastReviewer",
                flex: 0.3,
                minWidth: 120,
                renderCell: (params) => (
                    <HistoryInfo
                        content={params.value}
                        date={params.row.lastReviewDate}
                    />
                )
            });
        }

        return cols;
    }, [
        editable,
        groups.isApprover,
        groups.isEditor,
        langCode,
        onSetReview,
        onShouldSave,
        selectedView
    ]);

    return (
        <Container sx={{ height: "70vh", minHeight: 500, p: "0 !important" }}>
            <DataGrid
                rows={data ?? []}
                columns={columns}
                // Internally DataGrid checks for updates by whether the rowId changes
                // If any of these values change the row will be refreshed
                getRowId={row => `${row.path}_${row.key}_${row.value}_${row.comment}_${row.needsReview}`}
                disableColumnMenu
                slots={{
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: () => NoRowsOverlay({ rawData: unfilteredData })
                }}
                slotProps={{
                    cell: {
                        // Show tooltip on mousenter if cell content is elided
                        onMouseEnter: (event: React.MouseEvent<HTMLElement>) =>
                        {
                            // The elided text is in the first child element of the cell
                            const element = event.currentTarget.children[0] as HTMLElement;
                            if (   element
                                && (   element.offsetWidth < element.scrollWidth
                                    || element.offsetHeight < element.scrollHeight))
                            {
                                element.setAttribute("title", element.innerText);
                            }
                        }
                    }
                }}
                loading={loading || !data}
            />
        </Container>
    );
}
