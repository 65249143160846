import { isEmpty } from "lodash";

import { GetLatestResponse } from "../../models/Queries";
import { AuthRequest } from "../api-routes/localizations";
import { dexieHelper } from "../database/dexie";
import { RepoState } from "../hooks/repositoryData";

interface SyncProgress
{
    repo: string;
    branch: string;
    lastSync: string | null;
    lastKey: string | null;
}

export interface MergeUpdatesParams
{
    repoState: RepoState;
    client: AuthRequest;
    signal?: AbortSignal;
    onProgressDownload?: (progress: number) => void;
}

export const mergeUpdatesWithLocalDb = async ({
    repoState,
    client,
    signal,
    onProgressDownload,
}: MergeUpdatesParams) =>
{
    const { repoName: repo, branch, table } = repoState;
    // Localstorage key name
    const syncKey = `${repo}_${branch}_sync`;

    // SyncProgress keeps track of the last key we have downloaded
    // from DDB. If localstorage has an entry for sync progress it means
    // the download was interupted and we need to continue where we left off
    let syncProgress: SyncProgress;
    const res = localStorage.getItem(syncKey);
    if (res)
    {
        syncProgress = JSON.parse(res);
    }
    else
    {
        // Otherwise start the sync from the most recent date we have
        let lastSync: string | null = null;

        const lastSyncResult = await dexieHelper(table).lastSync();

        // This deals with an edge case while syncing
        // Items are inserted in batches 25 at a time all with the same lastSync
        // However its possible it was in the middle of a write and not all 25 were written
        // Go back 1 millisecond and grab the entire batch again to guarantee we get all 25
        if (lastSyncResult)
        {
            let date = new Date(lastSyncResult);
            date = new Date(date);
            date.setMilliseconds(date.getMilliseconds() - 1);
            lastSync = date.toISOString();
        }

        syncProgress = {
            repo,
            branch,
            lastSync,
            lastKey: null,
        };
    }

    let latestResponse: GetLatestResponse | null;
    do
    {
        latestResponse = await client.getLatest(syncProgress);

        if (latestResponse)
        {
            const localizations = latestResponse.items;
            await table.transaction("readwrite", table.localizations,
                async () => await table.localizations.bulkPut(localizations));

            // Only after inserting the items into local DB
            // we update local storage with our current sync progress
            syncProgress.lastKey = latestResponse.lastKey;
            localStorage.setItem(syncKey, JSON.stringify(syncProgress));
        }

        // If we encountered an error or we receive a signal to abort, we return early
        if (signal?.aborted)
        {
            throw new DOMException("Aborted", "AbortError");
        }
        onProgressDownload && onProgressDownload(latestResponse.items.length);
    } while (!isEmpty(JSON.parse(latestResponse.lastKey)));

    // Syncing is finished so we can clear the localstorage
    localStorage.removeItem(syncKey);
};
