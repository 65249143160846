import { ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";

import { LocalizationItem } from "../../../models/LocalizationItem";
import { isPlaceholderItem } from "../../../services/database/dexie";

export type FilterOptions = "missing" | "unsynced";

export const testFilter = (item: LocalizationItem, filterOptions: FilterOptions[], search: string) =>
{
    const upperCaseSearch = search.toUpperCase();

    // If neither the key nor value contains the search string then the item is filtered out
    // No need to check the other filters
    if (!item.key.toUpperCase().startsWith(upperCaseSearch)
        && !item.value?.toUpperCase().startsWith(upperCaseSearch))
    {
        return false;
    }

    for (const filter of filterOptions)
    {
        switch (filter)
        {
            case "missing":
                if (!isPlaceholderItem(item))
                {
                    return false;
                }
                break;

            case "unsynced":
                if (isPlaceholderItem(item) ||
                    (item.needsReview === item.pullReview
                        && item.comment === item.pullComment
                        && item.value === item.pullValue))
                {
                    return false;
                }
                break;
        }
    }

    return true;
};

export function FilterButtons({ filterChanged }: { filterChanged: (filters: FilterOptions[]) => void; })
{
    const [filters, setFilters] = useState<FilterOptions[]>([]);
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("md"));

    return (
        <ToggleButtonGroup
            color="primary"
            size="small"
            orientation={isSmallScreen ? "vertical" : "horizontal"}
            value={filters}
            onChange={(_, newFilters: FilterOptions[]) =>
            {
                setFilters(newFilters);
                filterChanged(newFilters);
            }}
        >
            <ToggleButton value="missing">Missing Localizations</ToggleButton>
            <ToggleButton value="unsynced">Unsynced</ToggleButton>
        </ToggleButtonGroup>
    );
}
