import CloseIcon from "@mui/icons-material/Close";
import { ButtonGroup, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

import { LocalizationItem } from "../../models/LocalizationItem";
import { useAuth } from "../../services/auth/AuthContext";
import { dexieHelper, LocalizationDexie } from "../../services/database/dexie";
import { downloadFiles } from "../../services/download-manager/translation";
import { useRepositoryData } from "../../services/hooks/repositoryData";
import { LoadingButton } from "../LoadingButton";
import { CreateExport } from "./exports/CreateExport";
import { ViewTypeKeys } from "./ViewPicker";

interface ActionButtonGroupProps
{
    disabled: boolean;
    viewKey: ViewTypeKeys;
    table?: LocalizationDexie;
    setPing: (ping: boolean) => void;
    fetchLatest: () => void;
}

const WarnExtendedPull = (
    <Typography variant="body2">
        A request to pull changes has been queued
        <br />
        This operation may take a few minutes to complete
    </Typography>
);

export const PULL_FROM_GH_QUERY = "pullFromGh";

export function ActionButtonGroup(
    {
        disabled,
        viewKey,
        table,
        setPing,
        fetchLatest
    }: ActionButtonGroupProps)
{
    const { authClient, groups } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { selectedRepoState, repoStates } = useRepositoryData();

    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("md"));

    /**
     * Make a request from the backend to pull the latest from GitHub
     * Afterwards update the UI to reflect those changes 
     */
    const { isLoading: isPulling, mutate: pullFromGh } = useMutation([PULL_FROM_GH_QUERY], async () =>
    {
        // While changes are already being synced, we don't want to ping
        setPing(false);
        const client = await authClient();
        try
        {
            await client.syncChanges({
                repo: selectedRepoState.repoName,
                branch: selectedRepoState.branch,
            });
            fetchLatest();
        }
        catch (error: unknown)
        {
            if (error instanceof Response)
            {
                if (error.status === 400)
                {
                    enqueueSnackbar("An invalid branch was specified", { variant: "error" });
                }
                // 504 happens when api gateway closes the connection after 30 seconds
                // However, localizations are still being loaded into the database
                else if (error.status === 504)
                {
                    // Load the changes that have been inserted so far
                    // The user will continue to receive notifications as more changes are available
                    warnProgress();
                    fetchLatest();
                }
                else
                {
                    enqueueSnackbar("Couldn't sync changes with GitHub", { variant: "error" });
                }
            }
        }
    });

    // Downloads all localizations from all repos grouped together
    // After downloading this should go through the import powershell script
    const { isLoading: isDownloading, mutate: handleGhDownload } = useMutation(["ghDownload"], async () =>
    {
        let items: LocalizationItem[] = [];
        for (const repoState of Object.values(repoStates))
        {
            items = items.concat(await dexieHelper(repoState.table).validItems());
        }

        await downloadFiles(
            new Date().toLocaleDateString(),
            items
        );
    });

    const warnProgress = useCallback(() =>
    {
        enqueueSnackbar(
            WarnExtendedPull,
            {
                variant: "warning",
                persist: true,
                action: (snackbarId) => (
                    <IconButton
                        onClick={() => closeSnackbar(snackbarId)}
                        size="small"
                        color="secondary"
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                )
            }
        );
    }, [closeSnackbar, enqueueSnackbar]);

    return (
        <ButtonGroup
            variant="outlined"
            orientation={isSmallScreen ? "vertical" : "horizontal"}
        >
            {viewKey === "localizations" && <>
                {groups.isPuller &&
                    <LoadingButton
                        onClick={() => pullFromGh()}
                        loading={isPulling}
                        label="Pull from GitHub"
                        disabled={disabled}
                    />
                }
                {groups.isExporter &&
                    <CreateExport
                        buttonLabel="Mark As Export Base"
                        dialogTitle="Create a New Export"
                        disabled={disabled}
                    />
                }
                <LoadingButton
                    onClick={() => handleGhDownload()}
                    loading={isDownloading}
                    label="Download For GitHub"
                    disabled={disabled}
                />
            </>
            }
            {viewKey === "export" &&
                <CreateExport
                    buttonLabel="Download for Translation"
                    dialogTitle="Download Files For Translation"
                    selectedExport={table}
                    disabled={disabled}
                />
            }
        </ButtonGroup>
    );
}
