import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Amplify } from "aws-amplify";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { OAUTH_DOMAIN, REDIRECT_SIGNIN, REDIRECT_SIGNOUT, USER_POOL_CLIENT_ID, USER_POOL_ID } from "./constants";
import { Home } from "./pages/Home";
import { SignIn } from "./pages/SignIn";
import { AuthProvider } from "./services/auth/AuthContext";
import { AuthenticatedRoute } from "./services/auth/AuthenticatedRoute";

// One-time setup to configure cognito
Amplify.configure({
    Auth: {
        userPoolId: USER_POOL_ID,
        userPoolWebClientId: USER_POOL_CLIENT_ID,
    },
    oauth: {
        domain: OAUTH_DOMAIN,
        redirectSignIn: REDIRECT_SIGNIN,
        redirectSignOut: REDIRECT_SIGNOUT,
        // The aws.cognito.signin.user.admin simply gives users permissions
        // to read their own attributes (needed to show email address in navbar)
        scope: ["email", "openid", "aws.cognito.signin.user.admin"],
        // "code" response type is needed for refresh tokens to work
        responseType: "code",
    }
});

export const AppRoutes = {
    Home: {
        path: "/",
        element: <Home />
    },
    SignIn: {
        path: "/signin",
        element: <SignIn />
    }
};

// Set up theme to match Panopto
const themeLight = createTheme({
    palette: {
        primary: {
            main: "#2F743F"
        },
        secondary: {
            main: "#FEFEFF"
        },
        background: {
            default: "#FCFCFE"
        },
    },
    typography: {
        h1: {
            fontSize: "32px"
        },
        h2: {
            fontSize: "28px"
        },
        h3: {
            fontSize: "24px"
        },
        h4: {
            fontSize: "20px"
        },
        h5: {
            fontSize: "16px"
        },
        h6: {
            fontSize: "12px"
        }
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "rgba(0, 0, 0, 0.8)"
                }
            }
        }
    }
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

export function App()
{
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <ThemeProvider theme={themeLight}>
                        <SnackbarProvider
                            preventDuplicate
                            maxSnack={3}
                        >
                            <CssBaseline />
                            <Router>
                                <Routes>
                                    <Route element={<AuthenticatedRoute />}>
                                        <Route {...AppRoutes.Home} />
                                    </Route>
                                    <Route {...AppRoutes.SignIn} />
                                </Routes>
                            </Router>
                        </SnackbarProvider>
                    </ThemeProvider>
                </AuthProvider>
            </QueryClientProvider>
        </>
    );
}
