import useLocalStorage from "@rehooks/local-storage";

import { LANG_CODES } from "../../components/table/filters/LanguageCodeSelector";
import { ViewTypeKeys } from "../../components/table/ViewPicker";
import { LocalizationItem } from "../../models/LocalizationItem";
import { Repository } from "./repositoryData";

// This file standardizes useLocalStoarge hooks, since its important
// they all have the same default values for all components they appear in

export function useSelectedView()
{
    return useLocalStorage<ViewTypeKeys>("table-view", "localizations");
}

export function useSelectedExport()
{
    return useLocalStorage<LocalizationItem | undefined>("selectedExport", undefined);
}

export function useSelectedLangCode()
{
    return useLocalStorage("langCode", LANG_CODES[0]);
}

export function useSelectedReposForExport()
{
    return useLocalStorage<Repository[]>("selectedReposForExport", ["core"]);
}
