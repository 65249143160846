export let USER_POOL_ID: string;
export let USER_POOL_CLIENT_ID: string;
export let WEB_API_URL: string;
export let OAUTH_DOMAIN: string;
export let REDIRECT_SIGNIN: string;
export let REDIRECT_SIGNOUT: string;

if (process.env.NODE_ENV === "production")
{
    USER_POOL_ID = "us-west-2_QI80ZjSIf";
    USER_POOL_CLIENT_ID = "40m1rkgo369lmprsljqjgj2i40";

    WEB_API_URL = "https://k2h5tffky6.execute-api.us-west-2.amazonaws.com/Prod/";
    OAUTH_DOMAIN = "panopto-string-review-users-prod.auth.us-west-2.amazoncognito.com/";

    REDIRECT_SIGNIN = "https://string-review.d.panopto.com/";
    REDIRECT_SIGNOUT = "https://string-review.d.panopto.com/signin/";
}
else
{
    USER_POOL_ID = "us-west-2_pE3uy7NNY";
    USER_POOL_CLIENT_ID = "5o22shuv6gb46q27u6unrgjso7";

    WEB_API_URL = "http://localhost:65394/";
    OAUTH_DOMAIN = "panopto-string-review-users-dev.auth.us-west-2.amazoncognito.com/";

    REDIRECT_SIGNIN = "http://localhost:3000/";
    REDIRECT_SIGNOUT = "http://localhost:3000/signin/";
}

export const CORE_REPO = "panopto-core";
export const MOBILE_REPO = "react-native-mobile";
