interface FileNamePathParams
{
    path: string;
    langCode: string;
    exportForGh?: boolean;
}

export const fileNameFromPath = ({ path, langCode, exportForGh }: FileNamePathParams): string =>
{
    let newPath = pathToFileName(langCode)[extractFolderPath(path)];

    // If we are downloading for GitHub skip the `cleanResx` and `json` subfolders
    if (exportForGh)
    {
        newPath = newPath.substring(newPath.indexOf("/") + 1);
    }
    return newPath;
};

export const extractFolderPath = (path: string) =>
{
    // Remove the file name and only keep the folder portion
    return path.substring(0, path.lastIndexOf("/") + 1);
};

// Files are renamed to match the expected output format
// Panopto/Applications/Recorder/Properties/ -> cleanResx/Recorder_Resources.en.resx
// The new file names are based on the folder path
// This process is reversed on the import so its important that the file names match what is expected
export const pathToFileName = (langCode: string): Record<string, string> => ({
    "Panopto/Applications/Recorder/Properties/": `cleanResx/Recorder_Resources.${langCode}.resx`,
    "Panopto/Applications/RecorderShared/Properties/": `cleanResx/RecorderShared_Resources.${langCode}.resx`,
    "Panopto/Data/DataLib/Properties/": `cleanResx/DataLib_Resources.${langCode}.resx`,
    "Panopto/Services/RemoteRecorder/Properties/": `cleanResx/RemoteRecorder_Resources.${langCode}.resx`,
    "WebUI/App_GlobalResources/": `cleanResx/WebUI_GlobalResources.${langCode}.resx`,
    "web/locales/master/": `json/locales_master.${langCode}.json`,
    "web/webrecorder/locales/": `json/locales_webrecorder.${langCode}.json`,
    "web/webpack/locales/": `json/locales_share.${langCode}.json`,
    "src/resources/": `json/resources.${langCode}.json`
});

export const LANGUAGE_MAP: { [key: string]: string; } = {
    "en": "English",
    "zh-Hans": "ChineseSimplified",
    "zh-Hant": "ChineseTraditional",
    "da": "Danish",
    "nl": "Dutch",
    "fr": "French",
    "de": "German",
    "it": "Italian",
    "ja": "Japanese",
    "ko": "Korean",
    "no": "Norwegian",
    "pt": "Portuguese",
    "ru": "Russian",
    "es": "Spanish",
    "th": "Thai",
    "tr": "Turkish",
    "cy": "Welsh",
};
