import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, TextField } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { LocalizationItem } from "../../../models/LocalizationItem";
import { useAuth } from "../../../services/auth/AuthContext";
import { dexieHelper, LocalizationDexie } from "../../../services/database/dexie";
import { downloadFiles } from "../../../services/download-manager/translation";
import { useSelectedReposForExport } from "../../../services/hooks/localStorageHooks";
import { useRepositoryData } from "../../../services/hooks/repositoryData";
import { LoadingButton } from "../../LoadingButton";
import { EXPORT_DROPDOWN_QUERY } from "../filters/ExportDropdown";
import { ExportRepoPicker } from "./ExportRepoPicker";

export const CREATE_EXPORT_QUERY = "createExport";

interface CreateExportProps
{
    disabled?: boolean;
    buttonLabel: string;
    dialogTitle: string;
    selectedExport?: LocalizationDexie;
}

export function CreateExport({
    selectedExport,
    buttonLabel,
    dialogTitle,
    disabled
}: CreateExportProps)
{
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [error, setError] = useState("");
    const [createExport, setCreateExport] = useState(false);

    const { authClient, groups } = useAuth();
    const [selectedRepos] = useSelectedReposForExport();
    const { repoStates } = useRepositoryData();
    const queryClient = useQueryClient();

    const handleClickOpen = () =>
    {
        setOpen(true);
        setName("");
        setError("");
        setCreateExport(false);
    };

    // Don't allow closing the popup if its still generating the export
    // Should take < 10 seconds to complete
    const handleClose = () =>
    {
        if (!isLoading)
        {
            setOpen(false);
        }
    };

    const { isLoading, mutate: handleSubmit } = useMutation([CREATE_EXPORT_QUERY, name], async () =>
    {
        if (!name)
        {
            return setError("Name field cannot be empty");
        }

        // Validate DBs aren't empty and retrieve all items
        let items: LocalizationItem[] = [];
        for (const repo of selectedRepos)
        {
            const table = repoStates[repo].table;
            const retrieved = await dexieHelper(table).validItems();
            if (retrieved.length === 0)
            {
                return setError(`The ${repoStates[repo].branch} branch of ${repoStates[repo].repoName} has not been synced`);
            }
            items = items.concat(retrieved);
        }

        // Only download the files on the export viewer. (Not when the user is marking a repo/branch as basis for export)
        if (selectedExport)
        {
            await downloadFiles(name, items, selectedExport);
        }

        // Optionally the user can choose to also create a new export in the DB
        // If there is no selectedExport that means the user is marking it as an export base
        if (createExport || !selectedExport)
        {
            const client = await authClient();
            await client.markExport({
                exportName: name
            }, selectedRepos.map(repo => ({
                repo: repoStates[repo].repoName,
                branch: repoStates[repo].branch
            })));

            // We have a new export so we need to invalidate the cache for the export dropdown
            queryClient.invalidateQueries([EXPORT_DROPDOWN_QUERY]);
        }

        setOpen(false);
    });

    return (
        <>
            <Button
                onClick={handleClickOpen}
                disabled={disabled}
            >
                {buttonLabel}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
            >
                <DialogTitle variant="h4">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <ExportRepoPicker />
                    {
                        // Only exporters can change the checkbox from the default "false" value
                        (selectedExport && groups.isExporter) &&
                        <>
                            <DialogContentText>
                                The selected repositories will have their localizations compared
                                to the opened export
                            </DialogContentText>
                            <FormControlLabel
                                label="Mark as new export"
                                control={
                                    <Checkbox
                                        checked={createExport}
                                        onChange={(event) => setCreateExport(event.target.checked)}
                                    />
                                }
                            />
                        </>
                    }
                    {!selectedExport &&
                        <DialogContentText>
                            The current working set of changes will be marked as an
                            export which can be compared against
                        </DialogContentText>
                    }
                    <TextField
                        margin="dense"
                        label="Enter a name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                    {error &&
                        <Alert
                            severity="error"
                            sx={{ mt: 2 }}
                        >
                            {error}
                        </Alert>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoading}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        onClick={handleSubmit}
                        label="Create"
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}
