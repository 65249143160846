import { Container, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { Navbar } from "../components/Navbar";
import { ActionButtonGroup } from "../components/table/ActionButtonGroup";
import { FilterButtons, FilterOptions } from "../components/table/filters/FilterButtons";
import { LocalizationsViewer } from "../components/table/LocalizationsViewer";
import { ViewPicker, ViewType } from "../components/table/ViewPicker";
import { LocalizationItem } from "../models/LocalizationItem";
import { dexieHelper, LocalizationDexie } from "../services/database/dexie";
import { useFetchLatest } from "../services/hooks/fetchLatest";
import { useSelectedLangCode, useSelectedView } from "../services/hooks/localStorageHooks";
import { useUpdateAlert } from "../services/hooks/updateAlert";

export function Home()
{
    const [langCode] = useSelectedLangCode();
    const [search, setSearch] = useState("");

    /*
     * Tables storing a local copy of data from DDB. One table per repo/branch
     * allows for independent switching of branches for each repo
    */
    const [table, setTable] = useState<LocalizationDexie>();
    const [viewKey] = useSelectedView();

    const [shouldPing, setShouldPing] = useState(false);
    const { refetch: fetchLatest, isLoading: downloading } = useFetchLatest({
        setShouldPing,
        setTable,
    });

    // Loop through each database (panopto-core, react-native-mobile) and filter data for the UI
    const { data, refetch, isFetching: loadingDb } = useQuery(["refreshData"], async ({ signal }) =>
    {
        let items: LocalizationItem[] = [];
        const filterLangCode = viewKey === "export" ? "en" : langCode;

        if (table)
        {
            items = await dexieHelper(table).filter(filterLangCode);
            if (filterLangCode !== "en")
            {
                items = items.concat(await dexieHelper(table).missingItems(items));
            }
        }

        return items;
    });

    const [filters, setFilters] = useState<FilterOptions[]>([]);
    const handleFilterChanged = (filters: FilterOptions[]) => setFilters(filters);

    useEffect(() =>
    {
        refetch();
    }, [refetch, langCode, table]);

    // Pings the server to check for updates
    useUpdateAlert({ shouldPing, handleRefresh: fetchLatest });

    return (
        <>
            <Navbar />
            <ViewPicker
                searchChanged={setSearch}
            />
            <Container sx={{ mt: 2, mb: 4 }}>
                <LocalizationsViewer
                    editable={ViewType[viewKey].editable}
                    loading={downloading || loadingDb}
                    data={data}
                    search={search}
                    filters={filters}
                />
                <Stack
                    direction="row"
                    mt={2}
                    justifyContent="space-between"
                    alignItems="start"
                >
                    <ActionButtonGroup
                        table={table}
                        viewKey={viewKey}
                        disabled={downloading || loadingDb}
                        setPing={setShouldPing}
                        fetchLatest={fetchLatest}
                    />
                    <FilterButtons
                        filterChanged={handleFilterChanged}
                    />
                </Stack>
            </Container>
        </>
    );
}
