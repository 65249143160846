import { Stack, Typography } from "@mui/material";

import { LocalizationItem } from "../../models/LocalizationItem";
import { useSelectedView } from "../../services/hooks/localStorageHooks";

export function NoRowsOverlay({ rawData }: { rawData?: LocalizationItem[] | null; })
{
    const [selectedView] = useSelectedView();
    const hasData = rawData && rawData.length > 0;

    return (
        <Stack
            height="100%"
            alignItems="center"
            justifyContent="center"
        >
            <Typography>
                {hasData && "No localizations found for query"}
                {!hasData && selectedView === "export" && "No exports found"}
                {!hasData && selectedView === "localizations"
                    && "No localizations found. Verify changes have been pulled."
                }
            </Typography>
        </Stack>
    );
}
