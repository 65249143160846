import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { Repository, useRepositoryData } from "../services/hooks/repositoryData";

export function RepositorySelector()
{
    const { repoStates, setSelectedRepoKey, selectedRepoKey } = useRepositoryData();

    return (
        <FormControl
            sx={{ m: 1, minWidth: 120, margin: 0 }}
            size="small"
        >
            <InputLabel>Repository</InputLabel>
            <Select
                value={selectedRepoKey}
                label="Repository"
                onChange={(event) => setSelectedRepoKey(event.target.value as Repository)}
                MenuProps={{
                    style: {
                        maxHeight: "400px"
                    }
                }}
            >
                {Object.entries(repoStates).map(([key, value]) =>
                    <MenuItem
                        key={key}
                        value={key}
                    >
                        {value.label}
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
}
