import { Close } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { useMemo, useState } from "react";

export interface SearchbarProps
{
    searchChanged: (value: string) => void;
}

export function Searchbar({ searchChanged }: SearchbarProps)
{
    const [search, setSearch] = useState("");

    const CloseButton = useMemo(() => (
        <InputAdornment position="end">
            {search &&
                <IconButton
                    onClick={() =>
                    {
                        setSearch("");
                        searchChanged("");
                    }}
                >
                    <Close fontSize="small" />
                </IconButton>}
        </InputAdornment>
    ), [searchChanged, search]);

    return (
        <Box
            sx={{
                width: "100%",
                maxWidth: "400px",
            }}
        >
            <TextField
                fullWidth
                label="Search"
                size="small"
                value={search}
                onChange={(event) =>
                {
                    setSearch(event.target.value);
                    searchChanged(event.target.value);
                }}
                InputProps={{ endAdornment: CloseButton }}
            />
        </Box>
    );
}
