import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useSelectedReposForExport } from "../../../services/hooks/localStorageHooks";
import { Repository, useRepositoryData } from "../../../services/hooks/repositoryData";

export function ExportRepoPicker()
{
    const [selectedRepos, setSelectedRepos] = useSelectedReposForExport();
    const { repoStates } = useRepositoryData();

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        selected: Repository[],
    ) =>
    {
        // Enforce at least one repo is selected
        if (selected.length)
        {
            setSelectedRepos(selected);
        }
    };

    return (
        <>
            <ToggleButtonGroup
                size="small"
                color="primary"
                sx={{ mb: 2 }}
                value={selectedRepos}
                onChange={handleChange}
            >
                {Object.entries(repoStates).map(([key, value]) => (
                    <ToggleButton
                        value={key}
                        key={key}
                        sx={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "250px",
                            display: "block",
                        }}
                    >
                        {value.label + " / " + value.branch}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </>
    );
}
