/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable max-len */
export function Logo()
{
    return (
        <svg style={{ maxWidth: "250px", width: "100%", minWidth: "100px" }} viewBox="0 0 514 116" fill="none" height="100%" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2_2)">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.5 88.9L41.7 107.5L51.1 112.9C53.9 114.5 57.1 115.4 60.5 115.4C63.9 115.4 67.2 114.5 69.9 112.9L95.2 98.3C100.8 95 104.6 88.9 104.6 82V70.7L82.8 57.2L41.7 81L28.1 88.9C25.4 90.4 22.2 91.4 18.8 91.4C15.4 91.3 12.2 90.5 9.5 88.9Z" fill="url(#paint0_linear_2_2)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M104.6 33.5V44.6V81.6C104.6 74.6 100.8 68.5 95.2 65.2L82.1 57.6L41.6 34.3V8.1L52 2.1C54.7 0.7 57.6 0 60.7 0C64.1 0 67.4 0.9 70.1 2.5L94.8 16.8C100.7 20.1 104.6 26.3 104.6 33.5Z" fill="url(#paint1_linear_2_2)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.5 88.9C3.8 85.7 0 79.6 0 72.6V43C0 36.4 3.4 30.6 8.5 27.2L19 21.2L50 3.3C45 6.7 41.6 12.5 41.6 19.1V81.1L28 89C25.3 90.6 22.1 91.5 18.7 91.5C15.4 91.3 12.2 90.5 9.5 88.9Z" fill="url(#paint2_linear_2_2)" />
                <path d="M435.3 36.3H414.2C413.7 36.3 413.2 35.9 413.2 35.3V22.8L404.7 24.3V71C404.7 76.6 405.2 83.5 408.7 88.1C412.4 92.8 418.3 93.8 423.9 93.8C428.6 93.8 432.8 92.9 437.2 91.1L435.6 84.3C433.5 85.1 431.4 85.9 429.1 86.3C426.2 86.7 422.5 86.8 419.8 85.8C413.7 83.8 413.1 76.4 413.1 70.9V44C413.1 43.5 413.5 43 414.1 43H435.2V36.3H435.3Z" fill="black" />
                <path d="M334.3 64.4C334.3 70.1 333.2 76 329.7 80.6C326.5 84.8 322.1 86.7 316.9 86.7C311.7 86.7 307.3 84.7 304.1 80.6C301 76.6 299.5 71.2 299.5 64.4C299.5 57.6 301 52.2 304.1 48.2C307.3 44 311.7 42.1 316.9 42.1C322.1 42.1 326.5 44.1 329.7 48.2C333.2 52.8 334.3 58.7 334.3 64.4ZM342.7 70.8C344.2 61.2 342.4 50.2 335.8 42.9C330.9 37.5 324.2 34.8 316.9 34.8C309.6 34.8 302.9 37.4 298 42.9C292.7 48.7 290.6 56.6 290.6 64.4C290.6 72.2 292.7 80 298 85.9C302.9 91.4 309.6 94 316.9 94C324.2 94 330.9 91.4 335.8 85.9C339.6 81.7 341.8 76.3 342.7 70.8Z" fill="black" />
                <path d="M210.1 34.8C205.3 34.8 198.9 35.4 194.3 37L195.3 43.7C195.7 43.6 196.2 43.4 196.7 43.3C197.7 43.1 198.8 42.8 200.1 42.6C202.7 42.1 205.8 41.9 209.3 41.9C212.7 41.9 215.9 42.5 218.7 44.6C220.8 46.3 222.2 48.7 222.8 51.4C223.1 52.9 223.7 58.4 223.4 59.8C223.3 60.3 222.8 60.6 222.2 60.5C219.5 59.8 216.1 59.3 213.4 59.1C209.9 58.9 206.1 59.3 202.7 60C199 60.8 195.1 62.5 192.4 65.3C189.5 68.3 188.4 71.9 188.4 76C188.4 81.3 189.9 86.4 194.4 89.6C198.8 92.8 204.8 93.6 210 93.6C214.7 93.6 219.3 93.2 224 92.6C226.6 92.3 229.2 91.9 231.8 91.5V56.1C231.8 50.7 230.9 44.9 227.2 40.7C225.6 38.8 223.4 37.4 220.6 36.4C217.2 35.2 213.5 34.8 210.1 34.8ZM211 86.9C207.6 86.9 203.8 86.4 200.8 84.6C198.1 83 196.8 80.1 196.8 76C196.8 72 198.8 69.1 202.4 67.4C204.9 66.2 207.4 65.8 210.1 65.7C213.3 65.5 219.7 65.6 222.6 67C223 67.1 223.2 67.5 223.2 67.9V85.2C223.2 85.6 222.9 86 222.5 86.1C219.1 86.9 214.6 86.9 211 86.9Z" fill="black" />
                <path d="M398.8 64.5C398.8 56.7 396.7 48.7 391.1 42.9C385.7 37.3 378.3 35 370.6 35C363.1 35 356.8 36.1 349.6 37.8V113.1H358.1V90.9C358.1 90.2 358.9 89.7 359.6 90.1C363.5 92.6 369.1 93.8 373.7 93.8C381 93.8 387.5 91.3 392.3 85.6C397.1 79.8 398.8 71.9 398.8 64.5ZM388.6 55.2C389.5 57.9 389.9 61 389.9 64.5C389.9 68.5 389.4 73.1 387.7 76.8C384.8 83.4 379.3 86.6 372.3 86.6C367.6 86.6 362.1 84.9 358.5 82C358.2 81.8 358.1 81.5 358.1 81.2V44.5C358.1 44.1 358.4 43.7 358.8 43.6C362.5 42.6 366.8 42.4 370.6 42.4C379 42.4 385.9 46.8 388.6 55.2Z" fill="black" />
                <path d="M485.1 64.4C485.1 70.1 484 76 480.5 80.6C477.3 84.8 473 86.7 467.7 86.7C462.5 86.7 458.1 84.7 454.9 80.6C451.8 76.6 450.3 71.2 450.3 64.4C450.3 57.6 451.8 52.2 454.9 48.2C458.1 44 462.5 42.1 467.7 42.1C472.9 42.1 477.3 44.1 480.5 48.2C484 52.8 485.1 58.7 485.1 64.4ZM493.5 70.8C495 61.2 493.2 50.2 486.6 42.9C481.7 37.5 475 34.8 467.7 34.8C460.4 34.8 453.7 37.4 448.8 42.9C443.5 48.7 441.4 56.6 441.4 64.4C441.4 72.2 443.5 80 448.8 85.9C453.7 91.4 460.5 94 467.7 94C475 94 481.7 91.4 486.6 85.9C490.4 81.7 492.6 76.3 493.5 70.8Z" fill="black" />
                <path d="M152.2 28.6C153.9 28.5 155.8 28.5 157.9 28.5C160.5 28.5 163.1 28.7 165.7 29.1C168 29.5 170.3 30.2 172.3 31.4C174.2 32.5 175.8 34.1 177 36C178.3 38.2 178.7 40.8 178.7 43.3C178.7 47.2 177.8 50.7 175 53.5C173.3 55.2 171.2 56.2 169 57C166.3 57.9 163.4 58.2 160.6 58.5C157.9 58.7 155.1 58.7 152.4 58.7C150.7 58.7 149 58.7 147.3 58.7V28.9C148.9 28.8 150.5 28.7 152.2 28.6ZM170.3 22.7C164.5 21.3 158.5 21.1 152.5 21.4C147.9 21.6 143.2 21.9 138.7 22.9V92.5H147.2V65.8C152.4 65.8 157.9 66 163.1 65.5C170.1 64.9 178 63.1 183 57.7C186.6 53.7 187.8 48.8 187.8 43.6C187.8 38.3 186.4 33.5 183.1 29.8C179.7 26 175 23.9 170.3 22.7Z" fill="black" />
                <path d="M239 37.7V92.4H247.5V44.4C247.5 43.9 247.8 43.5 248.3 43.5C252.1 42.7 256.3 42.4 260.1 42.4C264.7 42.4 269.4 43.3 272.3 47.3C274.5 50.3 275.1 54.5 275.4 58.1C275.5 59.6 275.6 61.3 275.6 63.1V92.5H284.1V60.8C284.1 58.8 284 57 283.8 55.2C283.3 50.5 282.3 45.9 279.3 42.1C274.9 36.5 268 35 261.2 35C253.5 35 246.5 35.9 239 37.7Z" fill="black" />
                <path d="M498.274 33.0428V32.1053H504.819V33.0428H502.075V40.8326H501.018V33.0428H498.274ZM506.45 32.1053H507.712L510.678 39.3496H510.78L513.746 32.1053H515.007V40.8326H514.018V34.2019H513.933L511.206 40.8326H510.251L507.524 34.2019H507.439V40.8326H506.45V32.1053Z" fill="black" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_2_2" x1="39.3106" y1="116.252" x2="72.4391" y2="63.7335" gradientUnits="userSpaceOnUse">
                    <stop offset="0.1133" stopColor="#C6DE89" />
                    <stop offset="0.3134" stopColor="#92BC71" />
                    <stop offset="0.6278" stopColor="#539453" />
                    <stop offset="0.8666" stopColor="#297E43" />
                    <stop offset={1} stopColor="#11773D" />
                </linearGradient>
                <linearGradient id="paint1_linear_2_2" x1="41.6397" y1="40.8326" x2="104.587" y2="40.8326" gradientUnits="userSpaceOnUse">
                    <stop offset="0.2266" stopColor="#11773D" />
                    <stop offset="0.3348" stopColor="#227C41" />
                    <stop offset="0.5004" stopColor="#428A4C" />
                    <stop offset="0.7027" stopColor="#6EA460" />
                    <stop offset="0.9321" stopColor="#ADCD7D" />
                    <stop offset={1} stopColor="#C6DE89" />
                </linearGradient>
                <linearGradient id="paint2_linear_2_2" x1="24.8631" y1="71.1499" x2="25.0201" y2="21.1223" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#11773D" />
                    <stop offset={1} stopColor="#C6DE89" />
                </linearGradient>
                <clipPath id="clip0_2_2">
                    <rect width="513.4" height="115.4" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
