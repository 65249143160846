import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { LocalizationItem } from "../../../models/LocalizationItem";
import { useAuth } from "../../../services/auth/AuthContext";
import { useSelectedExport } from "../../../services/hooks/localStorageHooks";

export const EXPORT_DROPDOWN_QUERY = "exports";

export function ExportDropdown()
{
    const { authClient } = useAuth();

    // New data will be grabbed every 5 minutes. Otherwise we use cache
    // We can invalidate cache when user makes a new export
    const STALE_TIME_MINUTES = 5;

    const [selectedExport, setSelectedExport] = useSelectedExport();

    const { data } = useQuery([EXPORT_DROPDOWN_QUERY], async () =>
    {
        const client = await authClient();
        const exports = await client.getRecentExports();
        const exportDict: { [key: string]: LocalizationItem; } = {};

        for (const item of exports)
        {
            // Technically exportName should always be valid
            // when we use the getRecentExports endpoint
            exportDict[item.exportName ?? ""] = item;
        }

        // If the user has never chosen an export, select the latest one automatically
        if (!selectedExport && exports.length)
        {
            setSelectedExport(exports[0]);
        }

        return exportDict;
    }, { staleTime: STALE_TIME_MINUTES * 60 * 1000 });

    const dropDownData = data ?? {};

    return (
        <FormControl
            sx={{ minWidth: 200 }}
            size="small"
        >
            <InputLabel>Exports</InputLabel>
            <Select
                // Mui will get angry if we have a selectedExport when the options
                // haven't loaded yet. The required default state is "" 
                value={
                    Object.keys(dropDownData).length
                        ? selectedExport?.exportName ?? ""
                        : ""
                }
                label="Exports"
                onChange={(event) => setSelectedExport(dropDownData[event.target.value])}
                disabled={!selectedExport?.exportName}
            >
                {Object.keys(dropDownData).map((key) => (
                    <MenuItem
                        key={key}
                        value={dropDownData[key].exportName}
                    >
                        {dropDownData[key].exportName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
