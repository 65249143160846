import { Checkbox, TextField } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useState } from "react";

import { LocalizationItem } from "../../../models/LocalizationItem";
import { isPlaceholderItem } from "../../../services/database/dexie";

interface EditableCellProps
{
    params: GridRenderCellParams;
    onShouldSave: (item: LocalizationItem) => void;
    disabled?: boolean;
}

export const NeedsReviewCheckbox = ({ params, onShouldSave, disabled }: EditableCellProps) =>
{
    const [value, setValue] = useState(params.value);
    disabled = isPlaceholderItem(params.row) || disabled;

    const handleChange = () =>
    {
        if (!disabled)
        {
            const newValue = value ? 0 : 1;
            setValue(newValue);
            onShouldSave({
                ...params.row,
                [params.field]: newValue
            });
        }
    };

    return (
        <Checkbox
            style={{
                pointerEvents: disabled ? "none" : "auto",
            }}
            checked={value !== 1}
            onChange={handleChange}
        />
    );
};

export const RowTextField = ({ params, onShouldSave, disabled }: EditableCellProps) =>
{
    const [value, setValue] = useState(params.value ?? "");
    disabled = isPlaceholderItem(params.row) || disabled;

    return (
        <TextField
            InputProps={{
                readOnly: disabled
            }}
            variant="standard"
            size="small"
            fullWidth
            onBlur={() =>
            {
                if ((value ?? "") !== (params.value ?? ""))
                {
                    onShouldSave({
                        ...params.row,
                        [params.field]: value
                    });
                }
            }}
            value={value}
            onChange={(event) =>
            {
                setValue(event.target.value);
            }}
            onKeyDown={(e) => e.stopPropagation()}
            inputProps={{
                style: {
                    paddingBottom: "2px",
                    fontSize: "15px"
                }
            }}
        />
    );
};
